@import './sass/mixins';
@import '../inquiry.main/index.scss';

.bg-section {
  //   display: -webkit-box;
  //   display: -webkit-flex;
  //   display: -ms-flexbox;
  //   display: flex;
  padding-top: 4rem;
  padding-bottom: 8rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(80%, transparent), to(#032832)),
    -webkit-gradient(linear, left top, left bottom, from(#032832), to(transparent)),
    url(../../public/static/images/Sea-01.jpeg);
  background-image: linear-gradient(180deg, transparent 80%, #032832), linear-gradient(180deg, #032832, transparent),
    url(../../public/static/images/Sea-01.jpeg);
  background-position: 0px 0px, 0px 0px, 50% 50%;
  background-size: auto, auto, cover;
  background-repeat: repeat, repeat, no-repeat;

  h1,
  h2,
  h3,
  h4,
  .MuiTypography-p {
    color: #fff;
  }

  .MuiTypography-p.text-center {
    display: block;
    text-align: center;
  }

  img,
  h2,
  h3,
  .MuiTypography-p {
    padding-bottom: 2rem;
  }
}

.text-color-white {
  color: #fff;
}

.banner-section {
  background-color: transparent;
  background-image: url(../../public/static/images/20210118174721bb29ee.jpg);
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  background-attachment: fixed;
  height: auto;
  padding: 3rem 1rem;

  @include breakpoint(medium) {
    height: 14rem;
    padding: inherit;
  }

  h2,
  .MuiTypography-p {
    color: #fff;
    text-align: center;
    display: block;
  }
  h2 {
    padding-top: 2rem;
  }
}

.ellipsis-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.product-card-item {
  display: flex;
  .product-card-media {
    width: 110px;
    height: 110px;
    position: relative;

    @include breakpoint(medium) {
      width: 150px;
    height: 150px;
    }
  }
  .img-bg-async {
    // position: absolute;
    // width: 100%;
    // height: 100%;

    width: 100%;
    height: 100%;
    // height: 110%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .product-card-content{
    .product-card-content-text{
      padding: 1.5rem;
    }
  }
  .product-card-controls{
    // display: flex;
    // align-items: center;
    // padding-left: 1rem;

    position: absolute;
    top: 0;
    right: 0;
  }
}

.product-card{

  .img-bg-async {
    // position: absolute;
    // width: 100%;
    // height: 100%;
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
  }
}

