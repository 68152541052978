@import '../app/sass/mixins';

.inquiry {
  .container {
    background: #032835;

    .MuiListItemText-primary {
      color: #fff;
    }
  }

  .img-card > div{
    // background-repeat: no-repeat;
    // background-size: cover;
    // // background-size: cover !important;
    // width: 150px;
    // height: 150px !important;
    
    // @include breakpoint(medium) {
      
    // }
  }

}
.inquiry-hero-section {
  background-position: 0px 0px, 0px 0px, 50% 50%;
  background-size: auto, auto, cover;
  background-repeat: repeat, repeat, no-repeat;

  // padding-top: 10rem;
  // padding-bottom: 12rem;

  padding-top: 8rem;
  padding-bottom: 8rem;

  &.hero-bg-0 {
    background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        from(#032832),
        color-stop(76%, rgba(3, 40, 50, 0))
      ),
      -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0.1))),
      url(../../public/static/images/Careers-Footer-CTA.jpg);
    background-image: linear-gradient(0deg, #032832, rgba(3, 40, 50, 0) 76%),
      linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
      url(../../public/static/images/Careers-Footer-CTA.jpg);
  }

  .hero-text-content {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: left;

    h1,
    p {
      color: #fff;
      margin-bottom: 1rem;
    }
    p {
      max-width: 100%;

      @include breakpoint(medium) {
        max-width: 50%;
      }
    }
    h1 {
      font-size: 3rem;
    }
    button {
      text-transform: capitalize;
      margin-top: 1rem;
      margin-right: 1rem;
    }
  }
}

.inquiry {
  .bg-section {
    h1,
    h2,
    h3,
    h4,
    .MuiTypography-p {
      color: inherit;
    }
  }
  .bg-section img,
  .bg-section h2,
  .bg-section h3,
  .bg-section .MuiTypography-p {
    padding-bottom: inherit;
  }

  .edit-title {
    .css-1eujsfn-MuiInputBase-input-MuiFilledInput-input {
      color: #fff;
    }
  }

  .inquiry-items {
    .css-1xr5q3p-MuiAvatar-root {
      background-color: #4caf50;
    }
  }

  .inquiry-item-card {
    img {
      height: 85px;
      width: 75px;

      @include breakpoint(medium) {
        height: 140px;
        width: 151px;
      }
    }
    .MuiCardHeader-title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 60%;
    }
  }
}
